<template>
  <div class="container">
    <div class="restaurant">
      <v-row class="">
        <v-col cols="12" class="top-panel">
          <div class="arrow-btn">
            <router-link
              v-if="
                user &&
                user.roles &&
                user.roles.length > 0 &&
                user.roles[0].employeeRole === 'Root'
              "
              to="/RestaurantRoot"
            >
              <img
                src="../assets/img/arrowBack.svg"
                class="img-pluss-btn"
                alt="arrow-back"
              />
            </router-link>
            <router-link v-else to="/RestaurantChoose">
              <img
                src="../assets/img/arrowBack.svg"
                class="img-pluss-btn"
                alt="arrow-back"
              />
            </router-link>
          </div>
          <div class="vertical-line-1"></div>
          <div class="add-btn">
            <img src="../assets/img/saveExit.svg" alt="saveExit" />
            <p class="add-text">Сохранить и закрыть</p>
          </div>
          <div class="add-btn">
            <img src="../assets/img/save.svg" alt="save" />
            <p class="add-text">Сохранить</p>
          </div>
          <div class="add-btn">
            <img src="../assets/img/cancelChange.svg" alt="cancelChange" />
            <p class="add-text">Отменить изменения</p>
          </div>
          <div class="vertical-line"></div>
          <div class="add-btn">
            <img src="../assets/img/delete.svg" alt="delete" />
            <p class="add-text">Удалить</p>
          </div>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="12" class="title-container py-0">
          <h1 class="page-title">Новый ресторан</h1>
          <!-- <div class="breadcrums-container">
            <router-link class="breadcrums-title" to="/Restaurants"
              ><span class="breadcrums-click">Рестораны / </span></router-link
            >
            <p class="breadcrums-title">CoffeonSaigon</p>
          </div> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="title-container-tabs">
          <template>
            <div>
              <v-row>
                <v-col cols="4">
                  <div class="content-container">
                    <v-col cols="12">
                      <h2 class="information-title">Информация</h2>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Название ресторана</p>
                        </v-col>
                        <v-col cols="1" class="py-0">
                          <span class="red-dot">*</span>
                        </v-col>
                        <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description"
                            v-model="nameRest"
                            ref="nameRestTextarea"
                            :placeholder="
                              isnameRestFocused ? '' : textareaPlaceholder
                            "
                            @focus="isnameRestFocused = true"
                            @blur="isnameRestFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="pb-0">
                      <div class="col-content">
                        <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Адрес</p>
                        </v-col>
                        <v-col cols="1" class="py-0">
                          <span class="red-dot">*</span>
                        </v-col>
                        <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description"
                            v-model="infoAdress"
                            ref="infoAdressTextarea"
                            :placeholder="
                              isinfoAdressFocused ? '' : textareaPlaceholder
                            "
                            @focus="isinfoAdressFocused = true"
                            @blur="isinfoAdressFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="pb-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Описание ресторана</p>
                        </v-col>
                        <v-col cols="1" class="py-0">
                          <span class="red-dot">*</span>
                        </v-col>
                        <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description"
                            v-model="descAdress"
                            ref="descAdressTextarea"
                            :placeholder="
                              isdescAdressFocused ? '' : textareaPlaceholder
                            "
                            @focus="isdescAdressFocused = true"
                            @blur="isdescAdressFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>

                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="pb-0">
                      <div class="col-content">
                        <v-col cols="4" class="col-paddings-r-l py-0">
                          <p class="col-title">
                            Номер телефона для связи клиентов
                          </p>
                        </v-col>
                        <v-col cols="1" class="py-0">
                          <span class="red-dot">*</span>
                        </v-col>
                        <v-col cols="6" class="col-paddings-r-l py-0">
                          <input
                            class="col-description"
                            v-model="phone"
                            ref="phoneTextarea"
                            v-mask="'+7 (###) ###-##-##'"
                            type="tel"
                            :placeholder="
                              isphoneFocused ? '' : textareaPlaceholder
                            "
                            @focus="isphoneFocused = true"
                            @blur="isphoneFocused = false"
                          />
                        </v-col>
                        <v-col
                          @click="callWork"
                          cols="1"
                          class="col-paddings-r-l tel-pointer"
                        >
                          <img src="../assets/img/call_phone.svg" alt="" />
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="pb-0">
                      <div class="col-content">
                        <v-col cols="4" class="col-paddings-r-l py-0">
                          <p class="col-title">Ссылка на инстаграмм</p>
                        </v-col>
                        <v-col cols="1" class="py-0">
                          <span class="red-dot">*</span>
                        </v-col>
                        <v-col cols="6" class="col-paddings-r-l py-0">
                          <textarea
                            class="col-description"
                            v-model="instagram"
                            ref="instagramTextarea"
                            :placeholder="
                              isinstagramFocused ? '' : textareaPlaceholder
                            "
                            @focus="isinstagramFocused = true"
                            @blur="isinstagramFocused = false"
                          ></textarea>
                        </v-col>
                        <v-col
                          @click="openInsta"
                          cols="1"
                          class="col-paddings-r-l tel-pointer"
                        >
                          <img src="../assets/img/instagram.svg" alt="" />
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="pb-0 mt-5">
                      <div class="col-content">
                        <v-col cols="4" class="col-paddings-r-l py-0">
                          <p class="col-title-work">Режим работы</p>
                        </v-col>
                        <v-col cols="1" class="py-0">
                          <span class="red-dot">*</span>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="1" class="col-paddings-r-l py-0">
                          <img
                            src="../assets/img/work_hour.svg"
                            class="work-img-margin"
                            alt=""
                          />
                        </v-col>
                      </div>
                      <v-col cols="12" class="px-0 work-margin-bot">
                        <div class="time-delivery-container">
                          <div class="start-container">
                            <p class="time-start mb-0">Время начала</p>
                            <input
                              type="text"
                              class="special-border"
                              v-model="timeStart"
                              @input="formatTime"
                              @keydown="handleBackspace"
                              placeholder="__:__"
                              maxlength="5"
                            />
                          </div>
                          <div class="end-container">
                            <p class="time-end mb-0">Время окончания</p>
                            <input
                              type="text"
                              class="special-border"
                              v-model="timeEnd"
                              @input="formatTime"
                              @keydown="handleBackspace"
                              placeholder="__:__"
                              maxlength="5"
                            />
                          </div>
                        </div>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="content-container">
                    <v-col cols="12">
                      <h2 class="information-title">Логотип и Картинка</h2>
                    </v-col>
                    <v-col cols="12" class="logo-flex">
                      <div class="d-flex">
                        <p class="mb-0 logo-dot">Логотип</p>
                        <span class="red-dot">*</span>
                      </div>
                      <div class="d-flex">
                        <div
                          v-if="uploadedImageLogo"
                          class="uploaded-image-container"
                        >
                          <img
                            :src="uploadedImageLogo"
                            alt="Uploaded Image"
                            class="uploaded-image-logo"
                          />
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          id="img-input-1"
                          @change="uploadImageLogo"
                          style="display: none"
                        />
                        <label for="img-input-1" class="custom-file-upload">
                          <img src="../assets/img/input-logo.svg" alt="" />
                        </label>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="logo-flex">
                      <div class="d-flex">
                        <p class="mb-0 logo-dot-2">Header логотип</p>
                        <span class="red-dot">*</span>
                      </div>
                      <div class="d-flex">
                        <div
                          v-if="uploadedImageHeader"
                          class="uploaded-image-container"
                        >
                          <img
                            :src="uploadedImageHeader"
                            alt="Uploaded Image"
                            class="uploaded-image-header"
                          />
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          id="img-input-2"
                          @change="uploadImageHeader"
                          style="display: none"
                        />
                        <label for="img-input-2" class="custom-file-upload">
                          <img src="../assets/img/input-logo.svg" alt="" />
                        </label>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="logo-flex">
                      <div class="d-flex">
                        <p class="mb-0 logo-dot-3">Фото ресторана</p>
                        <span class="red-dot">*</span>
                      </div>
                      <div class="d-flex">
                        <div
                          v-if="uploadedImagePhotoRest"
                          class="uploaded-image-container"
                        >
                          <img
                            :src="uploadedImagePhotoRest"
                            alt="Uploaded Image"
                            class="uploaded-image-photo-rest"
                          />
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          id="img-input-3"
                          @change="uploadImagePhotoRest"
                          style="display: none"
                        />
                        <label for="img-input-3" class="custom-file-upload">
                          <img src="../assets/img/input-logo.svg" alt="" />
                        </label>
                      </div>
                    </v-col>
                    <div class="horizontal-line margin-logo-divider"></div>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="content-container">
                    <v-col cols="12">
                      <h2 class="information-title">???</h2>
                    </v-col>
                    <v-col cols="12">
                      <div class="requisites-text-container">
                        <p class="requisites-title mb-0">
                          Минимальная сумма заказа
                        </p>
                        <input
                          type="text"
                          class="full-text-input"
                          v-model="minOrder"
                          ref="minOrderTextarea"
                          @input="formatminOrderInput('minOrder')"
                          @keydown="clearCurrencySymbolMinOrder($event)"
                          placeholder="0 ₸"
                          @focus="isminOrderFocused = true"
                          @blur="isminOrderFocused = false"
                        />
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12">
                      <div class="requisites-text-container">
                        <p class="requisites-title mb-0">
                          Наша комиссия с каждого заказа
                        </p>
                        <input
                          class="full-text-input-2"
                          v-model="commisionFromOrder"
                          ref="commisionFromOrderTextarea"
                          @input="
                            formatcommisionFromOrderInput(
                              'commisionFromOrderTextarea'
                            )
                          "
                          @keydown="
                            clearCurrencySymbolcommisionFromOrder($event)
                          "
                          placeholder="0 ₸"
                          @focus="iscommisionFromOrderFocused = true"
                          @blur="iscommisionFromOrderFocused = false"
                        />
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12">
                      <div class="requisites-text-container">
                        <p class="requisites-title mb-0">
                          Порядок ресторана в приложении
                        </p>
                        <textarea
                          class="full-text-input-3"
                          v-model="orderRestinApp"
                          ref="orderRestinAppTextarea"
                          :placeholder="
                            isorderRestinAppFocused ? '' : textareaPlaceholder
                          "
                          @focus="isorderRestinAppFocused = true"
                          @blur="isorderRestinAppFocused = false"
                        ></textarea>
                      </div>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
  
  
<style scoped>
.work-margin-bot {
  margin-bottom: 50px;
}
.col-title-work {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.special-border:focus {
  border-radius: 3px;
  border: 2px solid var(--03, black);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}
.special-border {
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}
.time-end {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}

.time-start {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}
.end-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.start-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}

.work-img-margin {
  margin-right: 21px;
}
.work-1 {
  text-align: end;
}
.dot-work {
  margin-left: 69px;
}
.work-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
}

.img-pluss-btn {
  margin-left: 10px;
}
.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* .top-panel .container {
    width: 100%;
    padding: 0px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  } */
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}
.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}
.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}
.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black; /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}
.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.full-text-input-3 {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}
.full-text-input-2 {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}
.full-text-input,
.full-text-input-2,
.full-text-input-3,
::placeholder {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.full-text-input,
:focus {
  outline: none; /* Убираем внешний контур (outline) при фокусе */
  border: none; /* Убираем границу (border) при фокусе */
  box-shadow: none; /* Убираем тень (box-shadow) при фокусе */
}
.requisites-text-container {
  display: flex !important;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: baseline;
}
.requisites-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 227px;
}
.margin-logo-divider {
  margin-bottom: 48px;
}
.logo-dot-3 {
  margin-right: 13px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-dot-2 {
  margin-right: 16px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-dot {
  margin-right: 69px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-file-upload {
  cursor: pointer;
}
.logo-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploaded-image-logo {
  max-width: 100px; /* Adjust this value as needed */
  max-height: 100px; /* Adjust this value as needed */
  margin-right: 21px;
}
.uploaded-image-header {
  max-width: 100px; /* Adjust this value as needed */
  max-height: 100px; /* Adjust this value as needed */
  margin-right: 21px;
}
.uploaded-image-photo-rest {
  max-width: 100px; /* Adjust this value as needed */
  max-height: 100px; /* Adjust this value as needed */
  margin-right: 21px;
}
.work-margin-img {
  margin-bottom: 15px;
}
.work-img {
  width: 22px;
  height: 22px;
}
.col-img {
  flex-direction: column-reverse;
  display: flex;
}
.work-margin {
  padding-bottom: 15px;
}
.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px; /* Толщина линии */
  background: #848484; /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
  margin-left: 12px;
}
.tel-pointer {
  cursor: pointer;
}
.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
}
.col-paddings-t-b {
  padding-top: 0px;
  padding-bottom: 0px;
}
.col-content {
  display: flex;
  align-items: flex-start;
}
.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  min-height: 20px; /* Минимальная высота для textarea */
}
.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}
.information-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.content-container {
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  background: var(--white, #fff);
  margin-top: 23px;
}
</style>

  <script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "CreateNewRest",
  directives: { mask },
  props: {
    requestData: {},
    responseData: {},
  },

  methods: {
    formatTime() {
      // Удалите все символы, кроме цифр
      this.timeStart = this.timeStart.replace(/\D/g, "");

      this.timeEnd = this.timeEnd.replace(/\D/g, "");

      // Ограничьте ввод до 4 символов
      if (this.timeStart.length > 4) {
        this.timeStart = this.timeStart.substring(0, 4);
      }

      if (this.timeEnd.length > 4) {
        this.timeEnd = this.timeEnd.substring(0, 4);
      }

      // Добавьте двоеточие после первых двух символов
      if (this.timeStart.length >= 2) {
        this.timeStart =
          this.timeStart.substring(0, 2) + ":" + this.timeStart.substring(2);
      }

      if (this.timeEnd.length >= 2) {
        this.timeEnd =
          this.timeEnd.substring(0, 2) + ":" + this.timeEnd.substring(2);
      }
    },

    formatcommisionFromOrderInput() {
      // Удаляем все символы, кроме цифр
      let value = this.commisionFromOrder.replace(/\D/g, "");

      // Если строка пуста, не выполняем форматирование
      if (value === "") {
        this.commisionFromOrder = "";
        return;
      }

      // Форматируем число с пробелами в качестве разделителя тысяч
      value = parseInt(value).toLocaleString("ru-RU").replace(/,/g, " ");

      // Добавляем двоеточие в конце
      value += "₸";

      // Обновляем значение модели
      this.commisionFromOrder = value;
    },
    formatminOrderInput() {
      // Удаляем все символы, кроме цифр
      let value = this.minOrder.replace(/\D/g, "");

      // Если строка пуста, не выполняем форматирование
      if (value === "") {
        this.minOrder = "";
        return;
      }

      // Форматируем число с пробелами в качестве разделителя тысяч
      value = parseInt(value).toLocaleString("ru-RU").replace(/,/g, " ");

      // Добавляем двоеточие в конце
      value += "₸";

      // Обновляем значение модели
      this.minOrder = value;
    },
    clearCurrencySymbolMinOrder(event) {
      if (event.key === "Backspace" && this.minOrder.length > 0) {
        this.minOrder = this.minOrder.slice(0, -1); // Удаляем последний символ
      }
    },
    clearCurrencySymbolcommisionFromOrder(event) {
      if (event.key === "Backspace" && this.commisionFromOrder.length > 0) {
        this.commisionFromOrder = this.commisionFromOrder.slice(0, -1); // Удаляем последний символ
      }
    },

    handleBackspace(event) {
      if (event.key === "Backspace" && this.timeStart.length > 0) {
        this.timeStart = this.timeStart.slice(0, -1); // Удаляем последний символ
      }

      if (event.key === "Backspace" && this.timeEnd.length > 0) {
        this.timeEnd = this.timeEnd.slice(0, -1); // Удаляем последний символ
      }
    },
    callWork() {
      if (this.phone) {
        window.location.href = `tel:${this.phone}`;
      }
    },

    openInsta() {
      if (this.instagram) {
        // Добавляем протокол, если его нет
        if (!/^https?:\/\//i.test(this.instagram)) {
          this.instagram = "https://" + this.instagram;
        }
        window.open(this.instagram, "_blank");
      }
    },

    uploadImageLogo(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedImage = file;
        this.uploadedImageLogo = URL.createObjectURL(file);
      }
    },
    uploadImageHeader(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedImage = file;
        this.uploadedImageHeader = URL.createObjectURL(file);
      }
    },
    uploadImagePhotoRest(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedImage = file;
        this.uploadedImagePhotoRest = URL.createObjectURL(file);
      }
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    hidePlaceholder(inputName) {
      if (inputName === "legalAdress") {
        this.isLegalAdressFocused = true;
      } else if (inputName === "actualAdress") {
        this.isActualAdressFocused = true;
      }
    },
    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
  },

  data() {
    return {
      selectedImage: null,
      uploadedImageLogo: "",
      uploadedImageHeader: "",
      uploadedImagePhotoRest: "",
      isPlaceholderHidden: false,
      isminOrderFocused: false,
      iscommisionFromOrderFocused: false,
      isorderRestinAppFocused: false,
      isdescAdressFocused: false,
      isinfoAdressFocused: false,
      isphoneFocused: false,
      isinstagramFocused: false,
      isnameRestFocused: false,
      minOrder: "",
      commisionFromOrder: "",
      nameRest: "",
      orderRestinApp: "",

      descAdress: "",
      infoAdress: "",
      phone: "",
      instagram: "",
      timeStart: "",
      timeEnd: "",
    };
  },
  watch: {
    orderRestinApp() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("orderRestinAppTextarea");
      });
    },

    FIOHEAD() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("FIOHEADTextarea");
      });
    },
    commisionFromOrder() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("commisionFromOrderTextarea");
      });
    },
    minOrder() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("minOrderTextarea");
      });
    },
    descAdress() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descAdressTextarea");
      });
    },
    infoAdress() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("infoAdressTextarea");
      });
    },
    phone() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("phoneTextarea");
      });
    },

    instagram() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("instagramTextarea");
      });
    },
    nameRest() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("nameRestTextarea");
      });
    },
  },
  computed: {
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },

    ...mapGetters({
      user: "user",
    }),
  },
};
</script>
  

  
  
  
  <style lang="scss">
@media (min-width: 1264px) {
  .container {
    //1185px дефолтное название если что то ломаться начнется на маленьких мониторах
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  //900 дефолтное название если что то ломаться начнется на маленьких мониторах
  .container {
    max-width: 100% !important;
  }
}
</style>